import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import {
  INSTRUMENT_PROJECTS,
  INSTRUMENT_SERVICES_API,
  REMINDER_DETAIL_API,
  REMINDER_DETAILS,
  FETCH_INSTRUMENT,
} from '../constants/routes';
import { ISimpleProject } from '../interfaces/components/Project';
import { ISimpleService } from '../interfaces/components/Service';
import { IFilter, IReduxStore } from '../interfaces/IGeneral';
import useGeneral from './useGeneral';
import { FilterFilter as ServiceFilter } from '../constants/filter';
import { IReminderDetail } from '../interfaces/components/Reminders';
import { IInstrument } from '../interfaces/components/Instrument';
import {
  RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  RESET_ORDER,
  SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER,
  SET_ORDER,
  SET_PAGINATION,
} from '../constants/reduxActions';
import usePagination from './usePagination';

function useInstrumentServices() {
  /* prettier-ignore */
  const { order, pagination, actualInstrumentServicesFilter: actualFilter, instrumentServicesPageSwitcher } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );
  const [projects, setProjects] = useState<Array<ISimpleProject>>([]);
  const [services, setServices] = useState<Array<ISimpleService>>([]);
  const [allServices, setAllServices] = useState<Array<ISimpleService>>([]);
  // const [servicesTotal, setServicesTotal] = useState<number>(0);
  const [filteredServices, setFilteredServices] = useState<Array<ISimpleService>>([]);
  const [reminders, setReminders] = useState<Array<IReminderDetail>>([]);
  const [total, setTotal] = useState<number>(1);
  const { handleApi } = useApi();
  const [switcherPage, setSwitcherPage] = useState(instrumentServicesPageSwitcher);
  const { id } = useParams<{ id: string }>();
  const { handleRedirect } = useGeneral();
  const dispatch = useDispatch();
  const [instrument, setIstrument] = useState<IInstrument>();
  const { paginate } = usePagination();

  useEffect(() => {
    getInstrument();
    fetchReminders();
  }, []);

  useEffect(() => {
    if (services && services.length > 0) {
      fetchAllServices();
    }
  }, [services]);

  useEffect(() => {
    switch (switcherPage) {
      case 0:
        if (order.orderByField === 'created_at') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
          return;
        }
        if (order.orderByField === 'scheduledDate' || order.orderByField === 'brand') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'next_date', orderByDirection: 'DESC' } });
          return;
        }
        fetchFilteredServicesWithPagination();
        break;
      case 1:
        if (order.orderByField === 'brand') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'title', orderByDirection: 'ASC' } });
          return;
        }
        fetchProjectsWithPagination();
        break;
      case 2:
        fetchRemindersWithPagination();
        break;
    }
  }, [pagination]);

  useEffect(() => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: 1 } });
    dispatch({ type: RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER });
    dispatch({ type: RESET_ORDER });
    toggleSwitcherPage(instrumentServicesPageSwitcher);
  }, [instrumentServicesPageSwitcher]);

  useEffect(() => {
    switch (switcherPage) {
      case 0:
        dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: 1 } });
        if (order.orderByField === 'created_at') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
          return;
        }
        if (order.orderByField === 'scheduledDate' || order.orderByField === 'brand') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'next_date', orderByDirection: 'DESC' } });
          return;
        }
        fetchFilteredServices();
        fetchServices();
        setTotal(filteredServices.length);
        break;
      case 1:
        if (order.orderByField === 'brand') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'title', orderByDirection: 'ASC' } });
          return;
        }
        fetchProjects();
        break;
    }
  }, [order, actualFilter]);

  async function fetchProjects() {
    if (order.orderByField === 'date') {
      return;
    }

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_PROJECTS.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setProjects(data.data);
  }

  async function fetchServices() {
    if (order.orderByField === 'date') {
      return;
    }

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setServices(data.data);
  }

  async function fetchAllServices() {
    if (order.orderByField === 'date') {
      return;
    }

    const query = '?page=1&perPage=999999';
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setAllServices(data.data);
  }

  async function fetchFilteredServices() {
    if (order.orderByField === 'date') {
      return;
    }

    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    // prettier-ignore
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${ServiceFilter[key] ? actualFilter[key]?.id : ( actualFilter[key]?.name ? actualFilter[key]?.name : '')}`
    ).join('');
    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}&page=1&perPage=${pagination.perPage}${answers}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setFilteredServices(data.data);
  }

  const fetchFilteredServicesWithPagination = async () => {
    if (order.orderByField === 'date') {
      return;
    }

    const keys = Object.keys(actualFilter) as Array<keyof IFilter>;
    // prettier-ignore
    const answers = keys.map(
      (key: keyof IFilter) => `&${[key]}=${ServiceFilter[key] ? actualFilter[key]?.id : ( actualFilter[key]?.name ? actualFilter[key]?.name : '')}`
    ).join('');
    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}&page=${pagination.page}&perPage=${pagination.perPage}${answers}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    paginate(setFilteredServices, data.data);
    setTotal(data.total);
  };

  async function fetchProjectsWithPagination() {
    if (order.orderByField === 'date') {
      return;
    }

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, INSTRUMENT_PROJECTS.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    paginate(setProjects, data.data);
    setTotal(data.total);
  }

  async function fetchReminders() {
    const query = `?orderByField=created_at&orderByDirection=DESC&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, REMINDER_DETAIL_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setReminders(data.data);
  }

  const fetchRemindersWithPagination = async () => {
    const query = `?orderByField=created_at&orderByDirection=DESC&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, REMINDER_DETAIL_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    paginate(setReminders, data.data);
    setTotal(data.total);
  };

  const getInstrument = async () => {
    const { data, code } = await handleApi(API_METHOD_GET, FETCH_INSTRUMENT.replace(':id', id));
    if (code !== 200) {
      return;
    }
    setIstrument(data);
  };

  const toggleSwitcherPage = (page: number) => {
    dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: page });
    setSwitcherPage(page);
  };

  const onReminderClick = (id: number) => {
    handleRedirect(REMINDER_DETAILS.replace(':id', id.toString()));
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  return {
    toggleSwitcherPage,
    switcherPage,
    projects,
    instrument,
    services,
    reminders,
    onReminderClick,
    filteredServices,
    nextPage,
    total,
    allServices,
    actualFilter,
    setFilteredServices,
  };
}

export default useInstrumentServices;
